import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Digitize your activities and present them to your guests. Have the app with
  your content only, embed interactive maps and lists on your websites, create
  customized collections for your activities and be in full controll of your
  content.
    </p>
    <Image src="solutions/presentation.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <h4>{`Present your activities from one source`}</h4>
    <p>{`Use one tool to present your activities in the Trailguide app, on your website,
as a data source for print products and as QR codes on signs.`}</p>
    <br />
    <p>{`Your content will be available on your individual Trailguide collection. On the
map and list users can explore your destination for activities, search and filter
for desired content, read comprehensive trail descriptions and reviews, as well as browse
inspirational pictures and videos.`}</p>
    <br />
    <br />
    <h4>{`Your destination on Trailguide`}</h4>
    <p>{`With Trailguide professional your destination is visible on the map and you have
your own landingpage. Your content is branded with your logo and name. A verified
badge shows that your content is official.
With our role based access control (RBAC) your organization
can have multiple administrators who can work with the software.`}</p>
    <Image src="solutions/destination_on_trailguide.jpeg" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <h4>{`Easily upload and manage content`}</h4>
    <p>{`Easily upload your `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/GPS_Exchange_Format"
      }}>{`GPX`}</a>{`
track files, pictures, videos, and add text to present your outdoor activities.
You can select the type of activity, difficulty, and select predefined
attributes to help your guests search and filter your content.`}</p>
    <Image src="solutions/uploadcontent2.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <h4>{`No installation needed`}</h4>
    <p>{`Since Trailguide is a
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Progressive_web_application"
      }}>{`web-app`}</a>{`
there is no need to download, install and register an app. Your guests
immediately get access to the content and can use the functions of the app.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      